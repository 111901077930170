<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <app-breadcrumb
      :breadcrumb="breadcrumb"
      :page-title="pageTitle"
    />
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="field in fields"
            :key="field.id + field.key"
            cols="12"
          >
            <b-form-group
              :label="field.name"
              :label-for="field.key"
              :description="field.description"
            >
              <b-form-input
                v-if="field.type === 'string'"
                :id="field.key"
                v-model.trim="item.data[field.key]"
                placeholder="Введите значение"
                :state="get(validation, [`data.${field.key}`, '0']) ? false : null"
              />
              <b-form-textarea
                v-else-if="field.type === 'text'"
                :id="field.key"
                v-model.trim="item.data[field.key]"
                placeholder="Введите значение"
                :state="get(validation, [`data.${field.key}`, '0']) ? false : null"
              />
              <b-form-checkbox
                v-else-if="field.type === 'boolean'"
                v-model="item.data[field.key]"
                :state="get(validation, [`data.${field.key}`, '0']) ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.data[field.key]">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <image-uploader-media
                v-else-if="field.type === 'image'"
                v-model="item.data[field.key]"
              />
              <images-uploader-media
                v-else-if="field.type === 'images'"
                v-model="item.data[field.key]"
              />
              <file-uploader-media
                v-else-if="field.type === 'file'"
                v-model="item.data[field.key]"
              />
              <files-uploader-media
                v-else-if="field.type === 'files'"
                v-model="item.data[field.key]"
              />
              <!--              <template v-if="field.type === 'image'">-->
              <!--                <pre>{{ item.data[field.key] || 'null' }}</pre>-->
              <!--              </template>-->
              <small
                v-if="!!get(validation, [`data.${field.key}`, '0'])"
                class="text-danger"
              >
                {{ get(validation, [`data.${field.key}`, '0']) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BFormTextarea, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import imageUploaderMedia from '@/components/ImageUploaderMedia.vue'
import imagesUploaderMedia from '@/components/ImagesUploaderMedia.vue'
import fileUploaderMedia from '@/components/FileUploaderMedia.vue'
import filesUploaderMedia from '@/components/FilesUploaderMedia.vue'

import vSelect from 'vue-select'

import get from 'lodash/get'
import {
  getCurrentInstance, computed, ref, set,
} from 'vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { createEntityItem } from '@/services/main-api/websites/externals/entity-items'
import useCrudCreate from '@/composables/useCrudCreate'
import { getEntity as getEntityApi } from '@/services/main-api/websites/externals/entities'
import { getEntityFields } from '@/services/main-api/websites/externals/entity-fields'
import { getWebsite } from '@/services/main-api/websites/externals/websites'

export default {
  name: 'CreateWarehouseView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    AppBreadcrumb,
    imageUploaderMedia,
    imagesUploaderMedia,
    fileUploaderMedia,
    filesUploaderMedia,
  },
  props: {
    entityID: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props, { root }) {
    const instance = getCurrentInstance().proxy

    const entity = ref(null)

    const getEntity = async () => {
      try {
        const res = await getEntityApi({
          id: props.entityID,
        })
        entity.value = res.data.data
      } catch (e) {
        entity.value = null
      }
    }

    const site = ref(null)

    const getSite = async () => {
      try {
        const res = await getWebsite({
          id: entity.value.website_id,
        })
        site.value = res.data.data
      } catch (e) {
        site.value = null
      }
    }

    const fields = ref([])

    const getFields = async () => {
      try {
        const res = await getEntityFields({
          entityID: props.entityID,
          orderBy: 'sort_order',
          sortedBy: 'asc',
          perPage: 1000,
        })
        fields.value = res.data.data

        fields.value.forEach(f => {
          if (f.type === 'boolean') {
            set(instance.item.data, f.key, false)
          } else if (f.type === 'files') {
            set(instance.item.data, f.key, [])
          } else if (f.type === 'images') {
            set(instance.item.data, f.key, [])
          } else if (f.type === 'file') {
            set(instance.item.data, f.key, null)
          } else if (f.type === 'image') {
            set(instance.item.data, f.key, null)
          } else {
            set(instance.item.data, f.key, '')
          }
        })
      } catch (e) {
        fields.value = []
      }
    }

    // BEGIN Breadcrumb
    const breadcrumb = computed(() => {
      const bItems = [
        {
          text: 'Сайты',
          to: { name: 'sites' },
        },
      ]

      if (site.value) {
        bItems.push({
          text: get(site.value, 'name', ''),
          to: { name: 'sites.external.show', params: { id: get(site.value, 'id', '') } },
        })
      }

      if (entity.value) {
        bItems.push({
          text: `Создание элемента ${get(entity.value, 'name', '')}`,
          active: true,
        })
      }
      return bItems
    })
    const pageTitle = computed(() => `Создание элемента ${get(entity.value, 'name', '')}`)
    // END Breadcrumb

    const createEntity = async () => {
      await createEntityItem({
        entity_id: props.entityID,
        data: instance.item.data,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        entity_id: null,
        data: {},
      },
      validation: {
        rules: {
          // name: 'required',
        },
        customErrorMessages: {
          // 'required.name': 'Укажите название',
        },
      },
      createEntity,
      getMoreData: async () => {
        await Promise.all([
          (async () => {
            await getEntity()
            await getSite()
          })(),
          getFields(),
        ])
      },
      getTextAfterCreation: () => 'Элемент Сущности был создан.',
      runAfterCreation: async () => {
        await instance.$router.push({ name: 'custom-model-items', params: { entityID: props.entityID } })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,
      fields,

      breadcrumb,
      pageTitle,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
